import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import store from "../store"; // Import store globally to avoid `require()`

Vue.use(VueRouter);
Vue.use(Meta);

// Lazy loading components for better performance
const MyLogIn = () => import("../views/patient/MyLogIn.vue");
const MyRegister = () => import("../views/patient/MyRegister.vue");
const MyForgetPassword = () => import("../views/patient/MyForgetPassword.vue");
const MyResetPassword = () => import("../views/patient/MyResetPassword.vue");
const MyProfile = () => import("../views/patient/MyProfile.vue");
const MyConsultation = () => import("../views/patient/MyConsultation.vue");
const MyVerifyEmail = () => import("../views/patient/MyVerifyEmail.vue");
const MyHome = () => import("../views/patient/MyHome.vue");
const VerifyMC = () => import("../views/VerifyMC.vue");

// Doctor Views
const MyDoctorLogIn = () => import("../views/doctor/MyDoctorLogIn.vue");
const MyDoctorConsultation = () => import("../views/doctor/MyDoctorConsultation.vue");
const MyDoctorConsultationHistory = () => import("../views/doctor/MyDoctorConsultationHistory.vue");
const MyDoctorAwaitingConsultation = () => import("../views/doctor/MyDoctorAwaitingConsultation.vue");
const MyDoctorMedication = () => import("../views/doctor/MyDoctorMedication.vue");

// Corporate Views
const CorporateLogin = () => import("../views/corporate/CorporateLogin.vue");
const CorporateDashboard = () => import("../views/corporate/CorporateDashboard.vue");

// Route Definitions
const routes = [
  { path: "/login", name: "Login", component: MyLogIn },
  { path: "/register", name: "Register", component: MyRegister },
  { path: "/forgetpassword", name: "Forget Password", component: MyForgetPassword },
  { path: "/resetpassword", name: "Reset Password", component: MyResetPassword },
  { path: "/profile", name: "My Profile", component: MyProfile },
  { path: "/verifyEmail", name: "Verify Email", component: MyVerifyEmail },
  { path: "/verifyMC", name: "Verify MC", component: VerifyMC },

  // Patient Routes (Authenticated)
  {
    path: "/",
    component: MyHome,
    meta: { requiresAuth: true }
  },

  {
    path: "/consultation/:action/:id?",
    meta: { requiresAuth: true },
    name: "Consultation",
    component: MyConsultation,
  },

  // Doctor Routes
  {
    path: "/doctor",
    component: { render: (h) => h("router-view") }, // Placeholder for nested routes
    children: [
      { path: "login", name: "Doctor Login", component: MyDoctorLogIn },
      { path: "consultation/id/:consultationId", meta: { requiresAuth: true }, component: MyDoctorConsultation },
      { path: "consultation/history", meta: { requiresAuth: true }, component: MyDoctorConsultationHistory },
      { path: "consultation/await", meta: { requiresAuth: true }, component: MyDoctorAwaitingConsultation },
      { path: "medication", meta: { requiresAuth: true }, component: MyDoctorMedication },
    ],
  },

  // Corporate Routes
  {
    path: "/corporate",
    component: { render: (h) => h("router-view") }, // Placeholder for child routes
    children: [
      { path: "login", name: "Corporate Login", component: CorporateLogin },
      { path: "dashboard", meta: { requiresAuth: true }, component: CorporateDashboard },
    ],
  },

  // Email Action Handling
  {
    path: "/emailAction",
    beforeEnter: (to, from, next) => {
      const mode = to.query.mode;
      let redirectPath = "/";

      if (mode === "resetPassword") redirectPath = "/resetpassword";
      if (mode === "verifyEmail") redirectPath = "/verifyEmail";

      next({ path: redirectPath, query: to.query });
    },
  },
];

// Create Router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return to.hash ? { selector: to.hash, behavior: "smooth" } : { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // Ensure Vuex store is initialized before routing logic
  if (!store.getters.isAuthenticated) {
    await store.dispatch("init");
  }

  // Determine if the route belongs to Doctor, Corporate, or Patient
  const isDoctorRoute = to.path.startsWith("/doctor");
  const isCorporateRoute = to.path.startsWith("/corporate");

  // If route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      // Prevent double redirection by checking if we're already navigating to login
      if (isDoctorRoute && to.path !== "/doctor/login") {
        next({ path: "/doctor/login", query: { redirect: to.fullPath } });
      } else if (isCorporateRoute && to.path !== "/corporate/login") {
        next({ path: "/corporate/login", query: { redirect: to.fullPath } });
      } else if (!isDoctorRoute && !isCorporateRoute && to.path !== "/login") {
        next({ path: "/login", query: { redirect: to.fullPath } });
      } else {
        next(); 
      }
    } else {
      // Role-based access control

      // Prevent non-doctors from accessing doctor routes
      if (isDoctorRoute && !store.getters.isDoctor) {
        next("/"); 
      
      // Prevent non-corporates from accessing corporate routes
      } else if (isCorporateRoute && !store.getters.isCorporate) {
        next("/"); 

      // Redirect doctors away from patient pages
      } else if (!isDoctorRoute && !isCorporateRoute && store.getters.isDoctor) {
        next("/doctor/consultation"); 
      } else {
        next(); 
      }
    }
  } else {
    // Redirect logged-in users away from login pages
    if (
      store.getters.isAuthenticated &&
      (to.name === "Login" || to.name === "Doctor Login" || to.name === "Corporate Login")
    ) {
      if (store.getters.isDoctor) {
        next("/doctor/consultation");
      } else if (this.$store.getters.isCorporate) {
        next("/corporate/dashboard");
      } else {
        next("/");
      }
    } else {
      next();
    }
  }
});


export default router;
