<template>
  <div class="nav-wrapper shadow">
    <b-navbar type="dark" variant="primary">
      <!-- Using 'to' for router-link integration on the brand -->
      <b-navbar-brand to="/doctor/consultation/await">
        <img :src="require(`@/assets/go-doc-logo-words.png`)" class="navbar__logo" />
      </b-navbar-brand>
      <b-navbar-nav>
        <div class="my-auto">
          <b-badge variant="light">
            {{ getUserType }}
          </b-badge>
        </div>
        <!-- Navigation for doctor -->
        <div v-if="getUserType === 'doctor'">
          <div class="d-flex flex-row flex-wrap">
            <b-nav-item to="/doctor/consultation/await">
              <span class="router-link">Pending</span>
            </b-nav-item>
            <b-nav-item to="/doctor/consultation/history">
              <span class="router-link">History</span>
            </b-nav-item>
          </div>
        </div>
        <!-- Navigation for corporate -->
        <div v-if="getUserType === 'corporate'">
          <b-nav-item to="/corporate/dashboard">
            <span class="router-link">Dashboard</span>
          </b-nav-item>
        </div>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <b-button type="button" variant="danger" @click="logOut" size="sm">Log Out</b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>
export default {
  computed: {
    getUserType() {
      return this.$store.state.userType;
    }
  },
  methods: {
    async logOut() {

      // update the authentication state by dispatching the logout action.
      await this.$store.dispatch("logout");
      window.location.reload();

    }
  }
};
</script>

<style lang="scss" scoped>
.nav-item {
  padding: 0px 15px;
}

.router-link {
  font-size: 24px;
  font-weight: 400;
  color: white;

  &.router-link--small {
    font-size: 16px;
  }

  &.router-link-active {
    font-weight: 700;
  }

  &:hover {
    text-decoration: none;
  }
}

.dot--small {
  height: 50px;
  width: 50px;
}

.navbar__logo {
  width: 150px;
}
</style>
