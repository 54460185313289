<template>
  <section class="content d-flex flex-column justify-content-center align-items-center maintenance-page">
    <b-container fluid="sm">
      <img :src="threeDoctorsSVG" class="mb-5 three-doctors-svg" />
      <h1 class="mb-3">RapiDr is in hibernation 😴</h1>
      <p>
        Hello!✨️</p>
        <p>⚠️ We are taking an extended break to focus on system upgrades for RapiDr.</p>
        <p>📧 Please refer to our social media for more information: <a href="https://www.instagram.com/rapidr.sg">https://www.instagram.com/rapidr.sg</a></p>
        <p>If you are looking for medical assistance, please refer to <a href="https://www.sgdi.gov.sg/other-organisations/hospitals">https://www.sgdi.gov.sg/other-organisations/hospitals</a></p>
        <p>For any outstanding admin matters, please contact our helpdesk at <a href="https://wa.me/6588013277">+6588013277</a> whom will gladly assist you on the matter.</p>
    </b-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      threeDoctorsSVG: require("@/assets/three-doctors.svg"),
    };
  },
  methods: {
    maintenanceContactForm() {
      window.open("https://forms.gle/ioZa5fiT3HHcPUSLA", "_blank");
    },
  },
};
</script>

<style>
.maintainance-page {
  height: 100vh;
  font-size: 2rem;
  font-weight: 600;
}

.three-doctors-svg {
  max-width: 500px;
}

.hyperlink {
  color: blue;
  text-decoration: underline;
}
</style>
